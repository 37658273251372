<template>
  <div class="pay-container">
    <div class="pay-head">
      <div class="flex align-center">
        <!-- <div>{{ busName }}-{{ info.loanNo }}</div> -->
        <div>付款金额</div>
      </div>
      <!-- 还本期 -->
      <div class="flex rem40 lh56 mt12" v-show="setlType == 1">
        <span>￥</span
        ><input
          type="number"
          v-model="iptrepayAmount"
          @change="checkInputNumber"
          class="ipt"
          @blur="change"
        />
      </div>
      <!-- 提前还款 -->
      <div class="rem40 lh56 mt12" v-show="setlType == 2">¥{{ info.repayAmount }}</div>

      <van-divider />
      <div class="rem26 lh18 c-8E929B">
        本金¥{{ info.prcp }}+利息¥{{ info.normInt
        }}<span v-show="info.fee">+手续费¥{{ info.fee }}</span
        ><span v-show="info.preFee">+提前付款手续费¥{{ info.preFee }}</span
        ><span v-show="info.odInt">+罚息¥{{ info.odInt }}</span>
      </div>
      <div v-show="remitAmt && flag" class="lh24 c-FF6646">
        信用减免红包（当日有效）{{ remitAmt }}元
      </div>
    </div>

    <div class="pay-card mt12 flex just-between">
      <div class="flex align-center">
        <img :src="bankImgUrl" class="mr6 imgms" />
        <div class="flex fleX-direction">
          <div class="rem30 lh21">{{ bankInfo.bankName }}({{ bankCard }})</div>
          <div class="rem26 lh18 c-8E929B">
            单笔{{ maxBankRestrictMoney }}元/单日{{ bankRestrictAmountEachDay }}元
          </div>
        </div>
      </div>
      <div class="flex align-center rem26 lh18 c-8E929B" @click="changeCard">
        <div>换卡支付</div>
        <van-icon name="arrow" />
      </div>
    </div>

    <div class="btn">
      <van-button round block color="#4D7BFE" @click="popPay">
        <span class="rem34">立即付款</span>
      </van-button>
    </div>

    <div class="flex just-around">
      <div class="flex rem30 lh21">
        <div class="c-8E929B">大额付款您可以：</div>
        <div class="flex align-center" @click="transferPay">
          <img :src="zhuanzhang" class="m5 imgzz" /><span class="c-4D7BFE">转账汇款</span>
        </div>
      </div>
    </div>

    <!-- 立即还款弹窗 -->
    <van-popup v-model="show" closeable close-icon="close" class="pop-pay">
      <div class="popup-title">付款支付确认</div>
      <div>
        <!-- 获取验证码 -->
        <div class="flex just-between rem30 lh21 mt56 mr16" v-if="verification === 1">
          <div>{{ phoneNo }}</div>
          <div class="c-4D7BFE mr16" @click="getVer">获取验证码</div>
        </div>
        <!-- 倒计时 -->
        <div class="flex just-between rem30 lh21 mt56 mr16" v-if="verification === 2">
          <input
            type="number"
            placeholder="请输入短信验证码"
            class="ipt timeIpt"
            v-model="verifyNum"
          />
          <div class="c-4D7BFE mr16">{{ timer }}S</div>
        </div>
        <!-- 重新获取 -->
        <div class="flex just-between rem30 lh21 mt56 mr16" v-if="verification === 3">
          <input type="number" placeholder="请输入短信验证码" class="ipt" v-model="verifyNum" />
          <div class="c-4D7BFE mr16" @click="getVerAgain">重新获取</div>
        </div>
      </div>

      <van-divider />
      <div class="mt35">
        <van-button round block color="#4D7BFE" v-debounce="confirm">
          <span class="rem34">确认</span>
        </van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
import Vue from 'vue'
import { Divider, Icon, Button, Popup } from 'vant'
import { Decrypt } from '@/utils/crypto'
import {
  getActiverepayRepayApi,
  getActiverepayTrialApi,
  sendRepayCodeApi,
  repayVerifyCodeApi,
  bankLimitApi,
  getUserBindCardListApi,
} from '@/apis/repayment/index'
// import { Decrypt } from '@/utils/crypto'
import { merchantRouteApi } from '@/apis/borrow/index'
import { mapState } from 'vuex'
import loadingIndex from '@/utils/overlay'
Vue.use(loadingIndex)

Vue.use(Divider)
Vue.use(Icon)
Vue.use(Button)
Vue.use(Popup)

export default {
  data() {
    return {
      wenhao: require('@/assets/images/payment/wh.png'),
      // staticBaseUrl: '../../../static/imgs/', //银行卡图标目录
      zhuanzhang: require('@/assets/images/payment/zz.png'),
      show: false,
      showRed: true,
      verification: 1,
      timer: 60,
      info: {}, // 还款试算
      bankInfo: {}, // 用户银行卡列表
      setlType: '', // 还款类型
      remitAmt: 0, //
      iptrepayAmount: '', // 输入的还款金额
      prcp: 0, // 应还利息
      normInt: 0, // 应还本金
      verifyNum: '', // 验证码数字
      timer1: null,
      phoneNo: null,
      busName: '',
      bankCardNo: '',
      flag: false,
      bankImgUrl: null,
      bankCode: null,
      bankRestrictAmountEachDay: null, //单日限额
      maxBankRestrictMoney: null, //单笔限额
      bindType: null,
      calType: '0',
      firstTrailData: null,
      merchantRoute: '',
      bankCard: '',
    }
  },
  created() {
    if (this.productCode.indexOf('cgd') != -1) {
      this.busName = '采易融'
    } else if (this.productCode.indexOf('kdd') != -1) {
      this.busName = '顺丰快递贷'
    }
    if (this.$route.query.setlType == '2') {
      this.flag = true
    } //控制减免金额的显示

    this.myLoan = this.$store.state.repayment.loan // 获取用户手机号 instSumAmt
    this.phoneNo = this.myLoan.maskPhoneNo
    this.info = this.$store.state.repayment.payInfo // 获取还款试算信息
    this.iptrepayAmount = this.info.repayAmount //默认显示金额

    this.setlType = this.$route.query.setlType // 还款类型
    this.remitAmt = Number(this.$route.query.remitAmt) // 减免金额
    if (this.setlType === '1') this.firstTrial() // 进页面调试算拿到还款总额
    this.bankImgUrl = this.bankCode + '.png'
    try {
      this.bankImgUrl = require(`../../assets/images/bankCard/${this.bankImgUrl}`)
    } catch (e) {
      this.bankImgUrl = require(`../../assets/images/bankCard/bk_510.png`)
    }
    this.getUserBindCardList()
  },
  computed: {
    ...mapState({
      productCode: (state) => state.common.productCode,
      businessType: (state) => state.common.businessType,
    }),
  },
  methods: {
    showloading() {
      var title = '加载中···'
      this.$showLoading({
        title: title,
      })
    },

    hideloading() {
      this.$cancelLoading()
    },

    // 立即还款
    popPay() {
      if (
        this.iptrepayAmount < this.maxBankRestrictMoney ||
        this.info.repayAmount < this.maxBankRestrictMoney
      ) {
        this.verifyNum = ''
        this.show = true
        this.timer = 60
        clearInterval(this.timer1)
        this.verification = 1
      } else {
        this.$common.toast(
          `该银行卡最高每笔${this.maxBankRestrictMoney}元，每日${this.bankRestrictAmountEachDay}元，请更换银行卡或者通过转账汇款。`
        )
        return
      }
    },

    // 银行卡限额
    getUserBindCardList() {
      let param = {bindType: this.myLoan.daishouMerNo};
      getUserBindCardListApi(param).then((res) => {
        const { bindType } = res.data[0]
        this.bindType = bindType //放款主体
        this.bankInfo = res.data[0] //获取银行卡信息
        this.bankCode = this.bankInfo.bankCode
        if (JSON.stringify(this.bankInfo) != '{}' && this.bankInfo.bankCardNo) {
          this.bankCardNo = Decrypt(this.bankInfo.bankCardNo)
          this.bankCard = this.bankCardNo.substr(-4)
        }
        if (this.bindType) this.merchantRouteFn()
      })
    },
    // 获取merchantRoute
    merchantRouteFn() {
      merchantRouteApi({
        companyCode: this.bindType,
        businessType: this.businessType,
      }).then((res) => {
        if (res.code == 200) {
          this.$store.commit('SET_MERCHANT_ROUTE', res.data)
          this.merchantRoute = res.data
          if (this.merchantRoute) this.bankLimit()
        }
      })
    },
    bankLimit() {
      bankLimitApi({
        bankCode: this.bankCode,
        bindType: this.bindType,
        businessCode: this.businessType,
        merchantRoute: this.merchantRoute,
      }).then((res) => {
        this.bankRestrictAmountEachDay = res.data.bankRestrictAmountEachDay
        this.maxBankRestrictMoney = res.data.maxBankRestrictMoney
      })
    },

    // 获取验证码
    getVer() {
      this.showloading()
      sendRepayCodeApi(this.myLoan.loanNo)
        .then((res) => {
          if (res.code === 200) {
            this.hideloading()
            console.log(res.data)
            this.verification = 2
            this.timer1 = setInterval(() => {
              this.timer -= 1
              if (this.timer === 0) {
                clearInterval(this.timer1)
                this.timer = 60
                this.verification = 3
              }
            }, 1000)
          } else {
            this.hideloading()
          }
        })
        .catch((err) => {
          console.log(err)
          this.hideloading()
          // this.$common.toast(err)
        })
    },
    // 进页面第一次试算
    firstTrial() {
      getActiverepayTrialApi({
        loanNo: this.myLoan.loanNo,
        setlType: '1',
        calType: this.calType,
        setlOrg: 'ACTV',
        repayAmount: this.iptrepayAmount || 0,
      }).then((res) => {
        if (res.code === 200) {
          this.remitAmt = res.data.remitAmt

          this.iptrepayAmount = res.data.repayAmount // 待还款总额
          this.info = res.data
          this.firstTrailData = res.data
          this.$store.commit('payInfoFn', this.info)
        }
      })
    },
    // 试算
    getActiverepayTrial() {
      getActiverepayTrialApi({
        loanNo: this.myLoan.loanNo,
        setlType: '1',
        calType: this.calType,
        setlOrg: 'ACTV',
        repayAmount: this.iptrepayAmount || 0,
      }).then((res) => {
        if (res.code === 200) {
          this.remitAmt = res.data.remitAmt

          this.iptrepayAmount = res.data.repayAmount // 待还款总额
          this.info = res.data
          this.$store.commit('payInfoFn', this.info)
        }
      })
    },

    // 只能输入两个小数
    checkInputNumber() {
      this.iptrepayAmount = this.iptrepayAmount.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3') //只能输入两个小数
    },
    // 失焦时试算
    change() {
      // 判断输入值是否合法
      if (this.iptrepayAmount <= this.firstTrailData.repayAmount && this.iptrepayAmount > 0) {
        if (this.iptrepayAmount !== this.firstTrailData.repayAmount) {
          this.calType = '1'
          this.getActiverepayTrial()
        } else {
          this.getActiverepayTrial()
        }
      } else {
        this.iptrepayAmount = ''
        this.$common.toast('仅支持提前还当期，请重新输入金额')
      }
    },

    // 重新获取验证码
    getVerAgain() {
      // this.verification = 1
      this.getVer()
    },

    // 确认还款
    confirm() {
      this.showloading()
      !this.verifyNum
        ? this.$common.toast('请先获取验证码')
        : // 验证验证码是否正确
          repayVerifyCodeApi({
            code: this.verifyNum,
            loanNo: this.myLoan.loanNo,
          })
            .then((res) => {
              // console.log(res.data)
              if (res.code === 200) {
                clearInterval(this.timer1)
                getActiverepayRepayApi({
                  isNeedPay: '1',
                  loanNo: this.info.loanNo, // 借据编号
                  setlType: this.setlType, // 还款类型
                  setlPrcp: this.info.prcp, // 还款本金
                  setlNormInt: this.info.normInt, // 还款利息
                  setlFee: this.info.fee, // 还款费用
                  setlTotAmt: Number(this.iptrepayAmount) || this.info.repayAmount, // 还款总金额
                  payAcctNo: this.bankInfo.bankCardNo, // 银行卡
                  payBankName: this.bankInfo.bankName, //银行名字
                  payBankCde: this.bankInfo.bankCode, //银行代码
                  setlOrg: 'ACTV',
                  pedrdNo: Number(this.myLoan.perdNo), // 还款期数
                  setlOdInt: this.info.odInt, //罚息
                  setlPreFee: this.info.preFee, //提前还款费用
                  setlRemitAmt: this.info.remitAmt,
                  // eslint-disable-next-line no-shadow
                })
                  .then((res) => {
                    // console.log(res.data)
                    if (res.code === 200) {
                      this.hideloading()

                      this.$router.push({
                        path: '/repaymentResult',
                        query: {
                          resultStatus: res.data,
                          amount: this.iptrepayAmount || this.info.repayAmount,
                        },
                      })
                    } else {
                      this.hideloading()
                    }
                  })
                  .catch(() => {
                    this.hideloading()
                  })
              }
            })
            .catch(() => {
              this.hideloading()
            })
    },

    // 换卡支付
    changeCard() {
      this.$router.push({ path: '/changeBankCard', query: { type: 'changeCard2' } })
    },
    // 转账汇款
    transferPay() {
      this.$router.push({ path: '/repaymentTransfer', query: { remitAmt: this.remitAmt } })
    },
  },
}
</script>

<style lang="less" scoped>
.pay-container {
  width: 100%;
  height: 100vh;
  background-color: #f8f8f8;
  box-sizing: border-box;
  .pay-head,
  .pay-card {
    background-color: #fff;
    padding: 0.22rem /* 11/50 */ 0.3rem /* 15/50 */;
    .ipt {
      border: none;
    }
  }

  .btn {
    margin: 0.62rem /* 31/50 */ 0.5rem /* 25/50 */;
  }

  .imgwh {
    width: 0.28rem /* 14/50 */;
    height: 0.28rem /* 14/50 */;
  }

  .imgzz {
    width: 0.32rem /* 16/50 */;
    height: 0.3rem /* 15/50 */;
  }
  .imgms {
    width: 0.64rem /* 32/50 */;
    height: 0.48rem /* 24/50 */;
  }
}

.pop-pay {
  width: 75%;
  border-radius: 5px;
  padding: 0.52rem /* 26/50 */ 0.46rem /* 23/50 */;
  .popup-title {
    display: flex;
    justify-content: space-around;
    font-size: 0.38rem /* 19/50 */;
    line-height: 0.52rem /* 26/50 */;
    font-weight: 500;
  }
  .ipt {
    border: none;
  }
}

/deep/ .van-divider {
  margin: 0.18rem /* 9/50 */;
}
</style>
